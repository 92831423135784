import React, { Component } from 'react';
import styled from 'styled-components';
import { color } from 'styled-system';
import { Spring } from 'react-spring/renderprops';
import Text from './text';
import TextElement from './text-element';
import { splitParagraphs } from '../utils/text-utils';
import theme, { media } from '../theme';
import SectionsGrid from './sections-grid';

const Container = styled.section`
  ${color};
  padding: ${theme.spacing[2]} ${theme.spacing[0]};
  width: 100%;

  ${media[0]`
    padding: ${theme.spacing[3]} ${theme.spacing[1]};
  `};

  ${media[1]`
    padding: ${theme.spacing[4]} ${theme.spacing[2]};
  `};

  ${media[2]`
    padding: ${theme.spacing[4]} ${theme.spacing[3]};
  `};
`;

const DescriptionWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: ${theme.spacing[2]};
  max-width: ${theme.maxWidths[0]};
  overflow: hidden;

  ${media[0]`
    margin-bottom: ${theme.spacing[3]};
  `};

  ${media[2]`
    margin-bottom: ${theme.spacing[4]};
    max-width: ${theme.maxWidths[1]};
  `};
`;

const ReadMore = styled(Text.Label)`
  cursor: pointer;
`;

export default class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.handleToggleText = this.handleToggleText.bind(this);
  }

  handleToggleText() {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  }

  render() {
    const { expanded } = this.state;
    const {
      language, section, pages, images,
    } = this.props;

    const paragraphs = splitParagraphs(section.description[language]);

    const ExpandWords = {
      en: ['Expand', 'Collapse'],
      it: ['Espandi', 'Comprimi'],
    };

    return (
      <Container bg={section.color}>
        <Text.H2 align="center">{section.title[language]}</Text.H2>
        <Spring force to={{ height: 'auto' }}>
          {(props) => (
            <DescriptionWrapper style={props}>
              <TextElement
                margin="0 auto 2rem auto"
                markdown={
                  expanded
                    ? section.description[language]
                    : paragraphs[0]
                }
              />
              { paragraphs.length > 1 && (
                <ReadMore onClick={this.handleToggleText}>
                  {expanded ? ExpandWords[language][1] : ExpandWords[language][0]}
                </ReadMore>
              ) }
            </DescriptionWrapper>
          )}
        </Spring>
        <SectionsGrid images={images} language={language} pages={pages} section={section} />
      </Container>
    );
  }
}

Section.defaultProps = {
  bg: 'white',
};
