import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import theme, { media } from '../theme';
import Text from './text';

const Logo = styled.img`
  margin-bottom: ${theme.spacing[2]};
  max-width: 20rem;
  max-height: 30vh;
  width: calc(5.5rem + 15vw);

  ${media[2]`
    width: calc(10rem + 10vw);
  `};
`;

const Title = styled(Text.H1)`
  color: ${theme.colors.white};
  text-transform: uppercase;
  margin: ${theme.spacing[2]};
`;

const Subtitle = styled(Text.H4)`
  color: ${theme.colors.white};
  max-width: 25em;
  margin: 0;
  text-align: center;
  padding: 0 1em;
`;

const HeaderImage = styled(Img)`
  height: 50vh;
  min-height: calc(15rem + 40vw);
  object-fit: cover;
  object-position: 0% 0%;

  ${media[1]`

  height: 100vh;
`};
`;

const HeaderImageContainer = styled.div`
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;

  &:after {
    background-color: rgba(17, 45, 78, 0.35);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const HeaderOverlay = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;
  min-height: calc(15rem + 40vw);
  position: relative;

  ${media[1]`
    height: 100vh;
`};
`;

const LandingHeader = (props) => {
  const {
    imageSizes, headerLogo, headerTitle, headerSubtitle,
  } = props;
  return (
    <>
      <HeaderImageContainer id="header">
        <HeaderImage sizes={imageSizes} />
      </HeaderImageContainer>
      <HeaderOverlay>
        <Logo src={headerLogo} />
        <Title>{headerTitle}</Title>
        <Subtitle>{headerSubtitle}</Subtitle>
      </HeaderOverlay>
    </>
  );
};

LandingHeader.propTypes = {
  imageSizes: PropTypes.object.isRequired,
  headerLogo: PropTypes.string.isRequired,
  headerTitle: PropTypes.string.isRequired,
  headerSubtitle: PropTypes.string.isRequired,
};

export default LandingHeader;
