import React from 'react';
import styled from 'styled-components';

const ControlsContainer = styled.div`
  border-radius: 3px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  font-weight: bold;
  justify-content: space-evenly;
  left: 1rem;
  overflow: hidden;
  position: absolute;
  transform: translateY(-1rem);
  z-index: 1;

  span {
    background: rgba(0, 0, 0, 0.4);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: .5;
    padding: .2rem .5rem;
    text-align: center;
    transition: .1s;
    z-index: 10;

  &:hover{
      opacity: 1;
    }
  }

`;

/* eslint-disable */
export default ({
  zoomIn, zoomOut,
}) => (
  <ControlsContainer>
    <span role="button" onClick={() => zoomIn()}>&#43;</span>
    <span role="button" onClick={() => zoomOut()}>&#8722;</span>
  </ControlsContainer>
);
