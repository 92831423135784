import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import theme, { media } from '../theme';
import Text from './text';

const Title = styled(Text.H3)`
  margin: 0;
`;

const Logo = styled.div`
  background-image: url(${(props) => props.source});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
`;

const LogoWrapper = styled.div`
  border: none;
  border-radius: 50%;
  display: inline-block;
  max-height: 10rem;
  max-width: 10rem;
  padding: 0rem;
  height: 100%;
  width: 100%;

  ${media[1]`
  border: 1px solid ${theme.colors.darkBlue};
  padding: 0.8rem;
  height: 4rem;
  width: 4rem;
  `};

  ${media[2]`
  padding: 1rem;
  height: 5rem;
  width: 5rem;
  `};
`;

const Column = styled.div`
  flex: ${(props) => props.flex};
  margin-right: 0;

  ${(props) => (props.logo
    ? `
      filter: blur( 2px );
      height: 100%;
      justify-content: center;
      opacity: 0.05;
      padding: 5vw;
      position: absolute;
      text-align: center;
      width: 100%;`
    : '')};

  ${media[1]`
  filter: none;
  height: initial;
  margin-right: calc(1rem + 1vw);
  opacity: 1;
  padding: 0;
  position: initial;
  width: initial;
`};

  ${media[2]`
  margin-right: 3rem;
`};
`;

const FlexItem = styled.div`
  color: ${theme.colors.darkBlue};
  display: flex;
  flex: 0 0 100%;
  margin-bottom: 2rem;
  position: relative;
  ${media[0]`
    flex: 0 0 48%;
  `};
`;

const StyledLink = styled(Link)`
  color: ${theme.colors.darkBlue};
  font-weight: 600;
  text-decoration: none;
  position: relative;
`;

const ReadMore = styled(Text.Label)`
  display: inline-block;
  opacity: 1;
  position: relative;
  transition: opacity 0.1s linear;

  &:after {
    background-image: url(/resources/arrow.svg);
    background-size: contain;
    content: "";
    display: inline-block;
    height: 0.75rem;
    margin-left: 0.2rem;
    transform: translate(0, 12%);
    transition: transform 0.1s ease-in;
    width: 0.75rem;
  }
  &:hover {
    opacity: 0.4;

    &:after {
      transform: translate(40%, 12%);
    }
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: ${theme.maxWidths[2]};
`;

function compare(a, b) {
  if (a.node.frontmatter.position && b.node.frontmatter.position === null) return -1;
  if (b.node.frontmatter.position && a.node.frontmatter.position === null) return 1;
  if (a.node.frontmatter.position < b.node.frontmatter.position) return -1;
  if (a.node.frontmatter.position > b.node.frontmatter.position) return 1;
  if (a.node.frontmatter.title.en < b.node.frontmatter.title.en) return -1;
  if (a.node.frontmatter.title.en > b.node.frontmatter.title.en) return 1;
  return 0;
}

const SectionsGrid = ({
  pages, section, language, images,
}) => (
  <Grid>
    {pages
      .filter(({ node: page }) => page.frontmatter.section === section.pageSlug)
      .sort(compare)
      .map(({ node: page }) => (
        <FlexItem key={page.frontmatter.pageSlug}>
          <Column logo flex="0 1 auto">
            <StyledLink to={page.frontmatter.pageSlug}>
              <LogoWrapper>
                <Logo
                  source={
                    images.find((i) => page.frontmatter.logo.includes(i.node.name)).node.publicURL
                  }
                />
              </LogoWrapper>
            </StyledLink>
          </Column>
          <Column>
            <>
              <Title>{page.frontmatter.title[language]}</Title>
              <Text.P>{page.frontmatter.description[language]}</Text.P>
              <StyledLink to={page.frontmatter.pageSlug}>
                <ReadMore>
                  {language === 'en' ? 'Read more' : 'Leggi di più'}
                </ReadMore>
              </StyledLink>
            </>
          </Column>
        </FlexItem>
      ))}
  </Grid>
);

SectionsGrid.propTypes = {
  section: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
};

export default SectionsGrid;
