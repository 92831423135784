import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import LandingHeader from '../components/landing-header';
import Layout from '../components/layout';
import { LanguageContext } from '../components/language-context-provider';
import PMap from '../components/map';
import Section from '../components/section';

function compare(a, b) {
  if (a.node.frontmatter.position && b.node.frontmatter.position === null) return -1;
  if (b.node.frontmatter.position && a.node.frontmatter.position === null) return 1;
  if (a.node.frontmatter.position < b.node.frontmatter.position) return -1;
  if (a.node.frontmatter.position > b.node.frontmatter.position) return 1;
  if (a.node.frontmatter.title.en < b.node.frontmatter.title.en) return -1;
  if (a.node.frontmatter.title.en > b.node.frontmatter.title.en) return 1;
  return 0;
}

const IndexPage = ({ data }) => {
  const indexPage = data.indexPage.edges[0].node;
  const images = data.images.edges;
  const contentPages = data.contentPages.edges;
  const sections = data.sections.edges;
  const {
    backgroundImage,
    headerLogo,
    headerSubtitle,
    headerTitle,
    mapMarkers,
    mapSettings,
  } = indexPage.frontmatter;

  return (
    <Layout>
      <LanguageContext.Consumer>
        {({ language }) => (
          <>
            <LandingHeader
              imageSizes={
                images.find(
                  (image) => backgroundImage.includes(image.node.name),
                ).node.childImageSharp.fluid
              }
              headerLogo={headerLogo}
              headerTitle={headerTitle}
              headerSubtitle={headerSubtitle[language]}
            />

            {sections
              .sort(compare)
              .map((section) => (
                <Section
                  section={section.node.frontmatter}
                  pages={contentPages}
                  language={language}
                  key={section.node.frontmatter.title.en}
                  images={images}
                />
              ))}

            {!!mapMarkers && (
              <PMap mapSettings={mapSettings} markers={mapMarkers} />
            )}
          </>
        )}
      </LanguageContext.Consumer>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    indexPage: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "index-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            backgroundImage
            headerLogo
            headerTitle
            headerSubtitle {
              en
              it
            }
            mapMarkers {
              label
              latitude
              longitude
            }
          }
        }
      }
    }

    contentPages: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "content-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title {
              en
              it
            }
            logo
            description {
              en
              it
            }
            pageSlug
            section
            position
          }
        }
      }
    }

    sections: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "section" },  onPage: { eq: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title {
              en
              it
            }
            description {
              en
              it
            }
            pageSlug
            section
            color
          }
        }
      }
    }

    images: allFile(
      filter: { internal: { mediaType: { regex: "/(image)/" } } }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            fluid(maxWidth: 1920, quality: 65) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
