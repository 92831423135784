import React, { Component } from 'react';
import styled from 'styled-components';
import Marker from 'pigeon-marker';
import Overlay from 'pigeon-overlay';
import Map from 'pigeon-maps';
import MapZoom from './map-zoom';
import Text from './text';
import theme from '../theme';

const MapContainer = styled.div`
  height: calc(200vh / 3);
  position: relative;
  width: 100%;
`;

const Label = styled(Text.Label)`
  color: white;
  display: block;
  ${(props) => (props.isFirst
    ? `
    font-size: calc(${theme.fontSizes[0]} + 0.2vw);
    font-weight: bold;
    `
    : '')};
  text-transform: none;
  text-align: center;
`;

const LabelContainer = styled.div`
  background: rgba(0, 0, 0, .5);
  display: block;
  border-radius: 3px;
  padding: 0.2rem 0.4rem;
  transform: translate(-50%, 5px);
`;

class PMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currMarker: 0,
      zoom: 4,
    };
    this.MAPTILER_ACCESS_TOKEN = 'rTrHrIKbDJYWjfgGX5pu';
    this.MAP_ID = 'bright';

    this.mapTilerProvider = (x, y, z, dpr) => `https://api.maptiler.com/maps/${this.MAP_ID}/256/${z}/${x}/${y}${dpr >= 2 ? '@2x' : ''}.png?key=${this.MAPTILER_ACCESS_TOKEN}`;
  }


  render() {
    const { markers } = this.props;
    const { currMarker } = this.state;
    const marker = markers[currMarker];

    return (
      <MapContainer>
        <MapZoom
          zoomIn={() => this.setState((prevState) => ({ zoom: Math.min(prevState.zoom + 1, 6) }))}
          zoomOut={() => this.setState((prevState) => ({ zoom: Math.max(prevState.zoom - 1, 3) }))}
        />
        <Map
          provider={this.mapTilerProvider}
          attribution={false}
          metaWheelZoom
          center={[marker.latitude, marker.longitude]}
          zoom={this.state.zoom}
          dprs={[1, 2]}
        >
          {markers.map((m) => (
            <Marker
              key={m.label}
              anchor={[
                Number.parseFloat(m.latitude),
                Number.parseFloat(m.longitude),
              ]}
            />
          ))}
          {markers.map((m) => (
            <Overlay
              key={m.label}
              anchor={[
                Number.parseFloat(m.latitude),
                Number.parseFloat(m.longitude),
              ]}
            >
              <LabelContainer>
                {m.label.split(/\n+/).map((p, i) => (
                  <Label key={p.substr(0, 20)} isFirst={i === 0}>
                    {p}
                  </Label>
                ))}
              </LabelContainer>
            </Overlay>
          ))}
        </Map>
      </MapContainer>
    );
  }
}

export default PMap;
